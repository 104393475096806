import React, { ReactNode } from 'react'

import UpSeatLogo from '@/assets/upseat_logo.svg'
import { motion } from 'framer-motion'
import { useNavigate } from 'react-router-dom'
import useScreenSize from '@/hooks/useScreenSize'

type PublicLayoutProps = {
  children: ReactNode
}

const PublicLayout: React.FC<PublicLayoutProps> = ({ children }) => {
  const screenSize = useScreenSize()
  const navigate = useNavigate()

  return (
    <div
      className={`app-view w-full bg-purple overflow-hidden ${screenSize.width > 1024 && 'pl-[33vw] pr-[33vw]'}`}
    >
      <div
        className={`h-[15vh] left-0 [&>figure]:h-fit [&>figure]:w-[33%] [&>figure>svg]:w-[100%] [&>figure>svg]:h-[100%] w-full flex justify-center items-center`}
      >
        <motion.img
          src={UpSeatLogo}
          onClick={() => navigate('/')}
          alt="upSeat-logo"
          className={`${screenSize.width > 1024 ? 'w-[3.5vw]' : 'w-[10vw]'} cursor-pointer`}
          initial={{ scale: 0.5 }}
          animate={{ rotate: 0, scale: 1 }}
          transition={{
            type: 'spring',
            stiffness: 260,
            damping: 20,
          }}
        />
      </div>
      {children}
    </div>
  )
}

export default PublicLayout
