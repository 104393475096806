import { ArtsSeatMap } from './Maps/Arts'
import { Frown } from 'lucide-react'
import { KilnSeatMap } from './Maps/Kiln'
import React from 'react'

interface SeatMapRouterProps {
  seatMapID: string
}

// Map seatMapID to components
const seatMapComponents: { [key: string]: React.ComponentType } = {
  'bbd9a7a1-188c-42a0-b3d3-460637e7326d': KilnSeatMap,
  '9cddd897-283c-43a2-938e-96dc6a5d8f74': KilnSeatMap,
  'a6b4c697-2e0f-4c58-bf75-ce945ea95b51': ArtsSeatMap,
}

const SeatMapRouter: React.FC<SeatMapRouterProps> = ({ seatMapID }) => {
  const SeatMapComponent = seatMapComponents[seatMapID]

  if (!SeatMapComponent) {
    return (
      <div className="h-[50vh] flex flex-col text-red2 gap-4 items-center justify-center">
        <Frown size={36} />
        <div>
          <p className="px-4 text-center text-pretty font-bold text-red2">{`Error loading your seat map.`}</p>
          <p className="px-4 text-center text-pretty font-bold text-red2">
            {`Please try it again or contact us`}{' '}
            <a
              className="underline text-purple"
              href="https://54dleo9n841.typeform.com/to/zfteuTMG"
            >
              here
            </a>
          </p>
        </div>
      </div>
    )
  }
  return <SeatMapComponent />
}

export default SeatMapRouter
