import { useEffect, useState } from 'react'

import { TicketsIcon } from '@/assets/navIcons/TabBarIcons'
import { motion } from 'framer-motion'

interface SeatsLoaderProps {
  dark?: boolean
}

const SeatsLoader = ({ dark }: SeatsLoaderProps) => {
  const [active, setActive] = useState<number>(0)
  const items: number[] = [0, 1, 2]

  useEffect(() => {
    const interval = setInterval(() => {
      setActive((prevActive) =>
        prevActive < items.length - 1 ? prevActive + 1 : 0,
      )
    }, 300)

    return () => clearInterval(interval)
  }, [items.length])

  return (
    <motion.div
      className="flex flex-col gap-4 items-center justify-center w-full h-[100vh]"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{
        opacity: 0,
      }}
    >
      <div className="z-30 flex flex-col gap-4 h-full items-center justify-center [&>svg]:h-[64px] [&>svg]:w-[64px] transition duration-180 ease-default-cubic fill-purple4">
        {TicketsIcon()}
        <p>{`Searching for UpSeats`}</p>
        <div className="loading-indicator flex gap-1 justify-center items-center">
          {items.map((number) => (
            <div
              key={number}
              className={`h-4 w-4 border transition rounded-sm ${
                dark ? `border-purple5` : `border-yellow`
              } ${
                active === number
                  ? dark
                    ? 'bg-purple2'
                    : 'bg-yellow'
                  : 'bg-transparent'
              }`}
            ></div>
          ))}
        </div>
      </div>
    </motion.div>
  )
}

export default SeatsLoader
