/* eslint-disable max-lines */
import { AnimatePresence, motion } from 'framer-motion'
import React, { useEffect, useRef, useState } from 'react'
import {
  deleteDatabase,
  fetchAllBookingsFromDB,
  startInterval,
  stopInterval,
  transition,
} from '@/utils'

import { Booking } from '@/types'
import { Button } from '@/components/ui/button'
import { CardList } from './CardList'
import LoadingIndicator from '../seatMap/LoadingIndicator'
import { TicketsIcon } from '@/assets/navIcons/TabBarIcons'
import { getWallet } from '@/service/Wallet/getWallet'
import { set } from 'idb-keyval'
import { syncBooking } from '@/service/Booking/syncBooking'
import { syncSilent } from '@/service/Booking/syncSilent'
import useCallRouteWithDirection from '@/hooks/useCallRouteWithDirection'
import { useDrag } from '@use-gesture/react'
import { useIndexes } from './useIndexes'
import useScreenSize from '@/hooks/useScreenSize'
import { useSpring } from '@react-spring/web'
import { useTicketsStore } from '@/context/useTicketsStore'
import { useUserStore } from '@/context/useUserStore'

interface Indexes {
  previousIndex: number
  currentIndex: number
  nextIndex: number
  afternextIndex: number
}

interface TicketStackProps {
  upgradeTicket: (order_id: string, instance_uid: string) => void
}
export const determineClasses = (
  walletLength: number,
  indexes: Indexes,
  cardIndex: number,
  isBackward: boolean,
) => {
  if (indexes.currentIndex === cardIndex) return 'active'

  if (isBackward) {
    // Logic when swiping backward
    if (indexes.previousIndex === cardIndex) return 'next' // When going backward, prev becomes next
    if (indexes.nextIndex === cardIndex) return 'prev' // The next card in forward swipe is the previous in backward
    if (indexes.afternextIndex === cardIndex) return 'afternext' // The afternext should be inactive when going backward
  } else {
    // Logic when swiping forward
    if (indexes.nextIndex === cardIndex) return 'next'
    if (indexes.previousIndex === cardIndex && walletLength > 3) return 'prev'
    if (
      indexes.afternextIndex === cardIndex ||
      (indexes.previousIndex === cardIndex && walletLength === 3)
    )
      return 'afternext'
  }

  return 'inactive'
}

export const TicketStack: React.FC<TicketStackProps> = ({ upgradeTicket }) => {
  const callRouteWithDirection = useCallRouteWithDirection()
  const { user } = useUserStore()
  const { wallet, setWallet } = useTicketsStore()
  const screenSize = useScreenSize()
  const getWalletIntervalRef = useRef<null>(null)
  const getWalletCallCounter = useRef<number>(0)
  const [loadingTickets, setLoadingTickets] = useState(true)
  const [syncLoading, setSyncLoading] = useState(false)
  const { indexes, handleCardTransition } = useIndexes(
    wallet ? wallet.length : 0,
  )
  const [backwardBlock, setBackwardBlock] = useState(true)

  const [{ y, rotateX, opacity }, api] = useSpring(() => ({
    y: 0,
    rotateX: 0,
    scale: 1,
    opacity: 1,
  }))

  const bind = useDrag(
    ({ down, movement: [, my] }) => {
      const rotation = -my / 40
      const scaling = down ? 0.95 : 1
      const opacityValue = down ? 1 : 1 // You can adjust opacity here if needed

      api.start(
        wallet && wallet.length > 1
          ? {
              y: down ? my : 0,
              rotateX: down ? rotation : 0,
              scale: scaling,
              opacity: opacityValue, // Maintain opacity during drag
            }
          : {},
      )

      if (!down && my < 250) {
        if (my < -50) {
          api.start({
            y: -600,
            rotateX: rotation,
            opacity: 1,
            scale: 1,
            config: { duration: 200, friction: 10 },
            onRest: () => {
              api.start({
                y: -750,
                rotateX: 0,
                opacity: 0,
                scale: 0,
                config: { duration: 200 },
                onRest: () => {
                  api.start({
                    y: 300,
                    rotateX: (rotation / 2) * -1,
                    opacity: 0,
                    scale: 0,
                    config: { duration: 50 },
                    onRest: () => {
                      api.start({
                        y: -5,
                        rotateX: 0,
                        opacity: 0.95,
                        scale: 0.95,
                        config: { duration: 300 },
                        onRest: () => {
                          api.start({
                            y: 0,
                            opacity: 1,
                            scale: 1,
                            config: { duration: 300 },
                          })
                        },
                      })
                    },
                  })
                },
              })
            },
          })
          setBackwardBlock(false)
          handleCardTransition(false)
        } else {
          api.start({ y: 0, rotateX: 0, scale: 1, opacity: 1 })
        }
      } else if (!down) {
        if (my > -250 && !backwardBlock) {
          api.start({
            y: 500,
            rotateX: rotation,
            opacity: 1,
            scale: 1,
            config: { duration: 200, friction: 10 },
            onRest: () => {
              api.start({
                y: 750,
                rotateX: 0,
                opacity: 0,
                scale: 0,
                config: { duration: 200 },
                onRest: () => {
                  api.start({
                    y: -300,
                    rotateX: (rotation / 2) * -1,
                    opacity: 0,
                    scale: 0,
                    config: { duration: 50 },
                    onRest: () => {
                      api.start({
                        y: 5,
                        rotateX: 0,
                        opacity: 0.95,
                        scale: 0.95,
                        config: { duration: 300 },
                        onRest: () => {
                          api.start({
                            y: 0,
                            opacity: 1,
                            scale: 1,
                            config: { duration: 300 },
                          })
                        },
                      })
                    },
                  })
                },
              })
            },
          })

          handleCardTransition(true)
        }
      }
    },
    { axis: 'y' },
  )

  const formatBookingToWallet = (bookings: Booking[]) => {
    const validBookings = bookings.filter(
      (booking: Booking) => booking.tickets.length,
    )

    const formattedBookings = validBookings.map((booking: Booking) => ({
      order_id: booking.order_id,
      uid: booking.uid,
      state: booking.state,
      config: booking.config,
      customer_id: booking.customer_id,
      total_price: booking.total_price,
      instance_uid: booking.tickets[0].instance_uid,
      event_name: booking.tickets[0].event_name,
      theatre_name: booking.tickets[0].theatre_name,
      start_time: booking.tickets[0].start_time,
      thumbnail_url: booking.tickets[0].thumbnail_url,
      tickets: booking.tickets,
    }))

    return formattedBookings
  }

  const loadUserTickets = () => {
    getWallet()
      .then(async (res) => {
        if (!res || !res.length) {
          setTimeout(() => {
            setWallet([])
            setLoadingTickets(false)
          }, 2000)
          return
        }

        const formattedBookings = formatBookingToWallet(res)

        // Create a set of order IDs from the server data
        const orderUIDsFromServer = new Set(
          formattedBookings.map((booking: Booking) => booking.order_id),
        )

        // Fetch current tickets from the database
        const currentTickets = await fetchAllBookingsFromDB()

        // Map existing order IDs in the current wallet
        const existingOrderUIDs = new Set(
          currentTickets.map((ticket: Booking) => ticket.order_id),
        )

        // Update or add tickets
        for (const booking of formattedBookings) {
          if (existingOrderUIDs.has(booking.order_id)) {
            // Update the ticket if it already exists
            await set(booking.order_id, booking)
          } else {
            // Add new ticket
            await set(booking.order_id, booking)
          }
        }

        // Remove tickets that are no longer in the server data
        for (const ticket of currentTickets) {
          if (!orderUIDsFromServer.has(ticket.order_id)) {
            // Delete any tickets that are not in the latest data
            deleteDatabase('keyval-store')
            window.location.reload()
          }
        }

        // Set wallet with the updated tickets
        setWallet(formattedBookings)
        setLoadingTickets(false) // Ensure loading state is updated after processing
      })
      .catch(async (error) => {
        console.error('Error fetching wallet', error)
        setSyncLoading(false)
        setLoadingTickets(false) // Ensure loading state is updated on error
        const currentTickets = await fetchAllBookingsFromDB()

        if (error.response.data.error.code === 1 && currentTickets.length) {
          // Delete the database if there are no tickets in the current wallet
          deleteDatabase('keyval-store')
          window.location.reload()
        }
      })

    getWalletCallCounter.current += 1

    if (getWalletCallCounter.current === 3) {
      syncSilent()
      getWalletCallCounter.current = 0 // Reset the counter
    }
  }

  useEffect(() => {
    if (wallet) {
      setSyncLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet])

  useEffect(() => {
    setSyncLoading(true)
    loadUserTickets()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    ;(async () => {
      const bookingsDB = await fetchAllBookingsFromDB()
      if (bookingsDB.length) {
        setWallet(bookingsDB)
        setLoadingTickets(false)
      }
    })()

    if (user) {
      startInterval(getWalletIntervalRef, loadUserTickets, 10000)
    }

    return () => {
      stopInterval(getWalletIntervalRef)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    if (wallet) {
      setLoadingTickets(false)
    }
  }, [wallet])

  return (
    <AnimatePresence>
      {loadingTickets && !syncLoading ? (
        <motion.div
          transition={{ delay: 0.2, duration: 0.5 }}
          initial={{ opacity: 0, translateY: -10 }}
          animate={{ opacity: 1, translateY: 0 }}
          className={`relative bottom-8 w-full h-full flex justify-center px-6`}
        >
          {/* <Skeleton
            className={`z-30 w-full h-[80svh] z-10 rounded-[35px] bg-purple3/30`}
          /> */}
        </motion.div>
      ) : wallet?.length ? (
        <CardList
          wallet={wallet}
          indexes={indexes}
          bind={bind}
          api={{ y, rotateX, opacity }}
          upgradeTicket={upgradeTicket}
        />
      ) : (
        <div className="relative bottom-[-3vh] w-full flex justify-center items-center px-6">
          <div
            className={`absolute bg-purple brightness-110 rounded-[35px] ${screenSize.width > 1024 ? 'w-[25vw]' : 'w-[80vw]'}`}
          ></div>
          <div className="bg-purple brightness-125 w-full h-[82.5svh] rounded-[35px]">
            <div className="z-30 flex flex-col gap-2 h-full items-center justify-center [&>svg]:h-[64px] [&>svg]:w-[64px] transition duration-180 ease-default-cubic fill-purple4">
              {TicketsIcon()}
              {syncLoading ? (
                <AnimatePresence>
                  <motion.div
                    transition={transition}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="flex flex-col gap-1 items-center justify-center [&>svg]:h-[64px] [&>svg]:w-[64px]"
                  >
                    <p>{`Syncing your tickets`}</p>
                    <span className="my-2">
                      <LoadingIndicator />
                    </span>
                  </motion.div>
                </AnimatePresence>
              ) : (
                <motion.div
                  transition={transition}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="w-full flex flex-col gap-1 justify-center items-center my-2"
                >
                  <p id="wallet-refresh-text">{'Can’t see your tickets?'}</p>
                  <Button
                    variant={'primaryYellow'}
                    className="py-2 rounded-[40px] mt-2"
                    id="wallet-refresh-btn"
                    onClick={async () => {
                      setSyncLoading(true)
                      setWallet(undefined)

                      syncBooking().then(() => {
                        loadUserTickets()
                      })
                    }}
                  >{`Refresh`}</Button>
                </motion.div>
              )}
            </div>
          </div>
        </div>
      )}
    </AnimatePresence>
  )
}
