import { transitionEasing, transitionEasingReverse } from '@/lib/global-vars'

import React from 'react'
import { motion } from 'framer-motion'
import useScreenSize from '@/hooks/useScreenSize'

type BottomScreenSlideProps = {
  children: React.ReactNode
  dimApp?: boolean
  dark?: boolean
  singleAction?: boolean
  callback?: Function
  close?: Function
  open?: boolean
  seatsSelected?: boolean
  infoModal?: boolean
}

export const CartScreenSlide = ({
  children,
  dimApp,
  dark,
  singleAction,
  callback,
  close,
  open,
  infoModal,
  seatsSelected,
}: BottomScreenSlideProps) => {
  const screenSize = useScreenSize()

  const transition = {
    ease: transitionEasing,
    duration: 0.325,
  }
  const transitionOut = {
    ease: transitionEasingReverse,
    duration: 0.125,
  }

  const closeIfOutside = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.currentTarget === e.target && close) {
      close()
    }
  }

  return (
    <motion.div
      transition={transition}
      initial={{ opacity: 0, translateY: 100, filter: 'blur(10px)' }}
      animate={{ opacity: 1, translateY: 0, filter: 'blur(0)' }}
      exit={{
        opacity: 0,
        translateY: 100,
        filter: 'blur(5px)',
        transition: transitionOut,
      }}
      className={`w-full bottom-sheet top-0 fixed z-20 left-0 ${
        dimApp && open ? 'bg-purple/70 h-[100dvh]' : 'h-[10svh] top-[90dvh]'
      }`}
      onClick={(e: React.MouseEvent<HTMLDivElement>) => closeIfOutside(e)}
    >
      <div
        className={`${screenSize.width > 1024 && 'pl-[33vw] pr-[33vw]'} ${
          open
            ? `min-h-[30dvh] h-auto overflow-scroll bottom-0`
            : `pb-0 overflow-hidden bottom-[-10svh]`
        } cart-sheet-content absolute left-0 w-full p-4 py-2 ${
          dark ? 'bg-purple text-white' : 'bg-white'
        } rounded-t-4xl
        ${infoModal ? `bottom-0 h-[57.5dvh]` : `h-[45dvh]`}
          `}
      >
        {children}
      </div>
    </motion.div>
  )
}
