import React, { useEffect, useState } from 'react'

import { Booking } from '@/types'
import { CardFlipper } from '../wallet/CardFlipper'
import { Indexes } from './useIndexes'
import { TicketCard } from './TicketCard'
import { TicketCardBackFace } from './TicketCardBackFace'
import { animated } from '@react-spring/web'
import { determineClasses } from '.'
import { motion } from 'framer-motion'

interface CardListProps {
  wallet: any[] // Define appropriate type
  indexes: Indexes
  bind: any
  api: any
  upgradeTicket: (order_id: string, instance_uid: string) => void
}

export const CardList: React.FC<CardListProps> = ({
  wallet,
  indexes,
  bind,
  api,
  upgradeTicket,
}) => {
  // const [inactiveCarousel, setInactiveCarousel] = useState(true)

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     setInactiveCarousel(false)
  //   }, 2000)
  //   return () => clearTimeout(timeout) // Cleanup on unmount
  // }, [])

  return (
    <>
      {wallet
        .sort((a: Booking, b: Booking) => {
          // First, compare by start_time
          const startTimeComparison =
            new Date(a.tickets[0].start_time).getTime() -
            new Date(b.tickets[0].start_time).getTime()

          // If start_time is the same, compare by seat_name alphabetically
          if (startTimeComparison !== 0) {
            return startTimeComparison
          }

          // Extract the last 4 characters
          const aLastFour = a.order_id.slice(-4)
          const bLastFour = b.order_id.slice(-4)

          // Compare the last 4 characters alphabetically
          return aLastFour.localeCompare(bLastFour)
        })
        .map((bookingOrder, index) => {
          const cardClass = determineClasses(
            wallet.length,
            indexes,
            index,
            api.y.get() > 0,
          )
          const isActive = cardClass === 'active'
          const isNext = cardClass === 'next'
          const isAfterNext = cardClass === 'afternext'
          const isPrev = cardClass === 'prev'
          const isInactive = cardClass === 'inactive'

          return (
            <div className={`w-full h-full`} key={index}>
              <motion.li
                initial={{
                  transform: isActive
                    ? 'translateY(2vh) scale(1)'
                    : isNext
                      ? 'translateY(9vh) scale(.95)'
                      : isAfterNext
                        ? 'translateY(18vh) scale(.9)'
                        : isInactive
                          ? 'scale(0)'
                          : '',
                }}
                animate={{
                  transform: isActive
                    ? 'translateY(0vh) scale(1)'
                    : isNext
                      ? 'translateY(-10vh) scale(.85)'
                      : isAfterNext
                        ? 'translateY(-17vh) scale(.75)'
                        : 'scale(0)',
                  filter: isActive
                    ? 'blur(0px) brightness(100%) opacity(1)'
                    : isNext
                      ? 'blur(.5px) brightness(80%) opacity(1)'
                      : isAfterNext
                        ? 'blur(1px) brightness(70%) opacity(.75)'
                        : 'blur(0px)',
                }}
                transition={{
                  type: 'spring',
                  stiffness: 200,
                  damping: 15,
                  mass: 0.05,
                }}
                className={`absolute w-full h-[85dvh] flex items-end
                ${cardClass}
                ${isActive && `z-30 scale-100`}
                ${isPrev && 'z-0 pointer-events-none'}
                ${isNext && 'z-20 pointer-events-none'}
                ${isAfterNext && 'z-10 pointer-events-none'}
                ${!isActive && !isNext && !isAfterNext && 'hidden'}
              `}
              >
                <animated.div
                  {...(wallet.length > 1 ? bind() : {})}
                  style={
                    isActive
                      ? {
                          transform: api.y
                            .to((y: any) => `translateY(${y}px)`)
                            .to(
                              (translateY: any) =>
                                `perspective(200px) ${translateY} rotateX(${api.rotateX.get()}deg)`,
                            ),
                          opacity: api.opacity,
                        }
                      : {}
                  }
                  className={`w-full h-full touch-none`}
                >
                  <CardFlipper
                    isCurrentCard={index === indexes.currentIndex}
                    index={index}
                    FrontFace={
                      <TicketCard
                        bookingTickets={bookingOrder}
                        upgradeTicket={() =>
                          upgradeTicket(
                            bookingOrder.uid,
                            bookingOrder.instance_uid,
                          )
                        }
                      />
                    }
                    BackFace={
                      <TicketCardBackFace
                        bookingTickets={bookingOrder}
                        upgradeTicket={() =>
                          upgradeTicket(
                            bookingOrder.uid,
                            bookingOrder.instance_uid,
                          )
                        }
                      />
                    }
                  />
                </animated.div>
              </motion.li>
            </div>
          )
        })}
    </>
  )
}
