import * as Slider from '@radix-ui/react-slider'

import { AnimatePresence, motion } from 'framer-motion'
import { transitionEasing, transitionEasingReverse } from '@/lib/global-vars'

import { PriceBand } from '@/types'
import { useSeatMapFilterStore } from '@/context/useSeatMapFilterStore'
import { useState } from 'react'

type PriceSliderProps = {
  pricePoints: PriceBand[]
}

const transition = {
  ease: transitionEasing,
  duration: 0.3,
}
const transitionOut = {
  ease: transitionEasingReverse,
  duration: 0.05,
}
const PriceSlider = ({ pricePoints }: PriceSliderProps) => {
  const [lowValue, setLowValue] = useState<number>(0)
  const [highValue, setHighValue] = useState<number>(pricePoints.length - 1)
  const { setDiscountRange } = useSeatMapFilterStore()

  const setValues = (values: number[]) => {
    setDiscountRange(values)
    setLowValue(values[0])
    if (values[1] + 1 <= pricePoints.length) {
      setHighValue(values[1])
    }
  }

  return (
    <div className="price-slider mt-2">
      {pricePoints.length > 1 ? (
        <>
          <Slider.Root
            className="relative w-full flex touch-none select-none items-center"
            defaultValue={[lowValue, highValue]}
            max={pricePoints.length - 1}
            step={1}
            onValueChange={setValues}
          >
            <Slider.Track className="relative h-1 w-full grow overflow-hidden rounded-full bg-grey2 mx-4">
              <Slider.Range className="absolute h-full bg-purple2" />
            </Slider.Track>
            <Slider.Thumb
              style={{
                backgroundColor:
                  'var(--tier' +
                  (pricePoints.indexOf(pricePoints[lowValue]) + 1).toString() +
                  ')',
              }}
              className={`slider-thumb block rounded-full
            ring-offset-white px-4 my-4
            transition-colors disabled:pointer-events-none
            focus-disabled: outline-none
            disabled:opacity-50
            flex justify-center items-center text-xs text-white`}
            >
              <div className="thumb-value w-max px-2 py-1 text-[12px] font-medium">
                {pricePoints[lowValue].active_discount * 100}% off
              </div>
            </Slider.Thumb>
            <Slider.Thumb
              style={{
                backgroundColor:
                  'var(--tier' +
                  (pricePoints.indexOf(pricePoints[highValue]) + 1).toString() +
                  ')',
              }}
              className={`slider-thumb block rounded-full
            ring-offset-white px-4 my-4
            transition-colors disabled:pointer-events-none
            focus-disabled: outline-none
            disabled:opacity-50
            flex justify-center items-center text-xs text-white`}
            >
              <div className="thumb-value w-max px-2 py-1 text-[12px] font-medium">
                {pricePoints[highValue].active_discount * 100}% off
              </div>
            </Slider.Thumb>
          </Slider.Root>
          <div className="price-slider-indicators w-full px-10 py-[5px] flex justify-between items-center mt-3">
            {pricePoints.map((pricepoint: PriceBand, index) => (
              <div
                key={pricepoint.uid}
                style={{
                  backgroundColor:
                    lowValue === index || highValue === index
                      ? 'transparent'
                      : 'var(--tier' + (index + 1).toString() + ')',
                }}
                className={`indicator rounded-full transition flex justify-center items-center text-xs w-2 h-2`}
              >
                <AnimatePresence>
                  {lowValue === index && lowValue !== highValue ? (
                    <motion.div
                      transition={transition}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0, transition: transitionOut }}
                    >
                      <div
                        style={{
                          color:
                            'var(--tier' +
                            (
                              pricePoints.indexOf(pricePoints[lowValue]) + 1
                            ).toString() +
                            ')',
                        }}
                        className="text-sm font-medium"
                      >
                        {`£${pricePoints[lowValue].discount_price}`}
                      </div>
                    </motion.div>
                  ) : null}
                  {highValue === index ? (
                    <motion.div
                      transition={transition}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0, transition: transitionOut }}
                    >
                      <div
                        style={{
                          color:
                            'var(--tier' +
                            (
                              pricePoints.indexOf(pricePoints[highValue]) + 1
                            ).toString() +
                            ')',
                        }}
                        className="text-sm font-medium"
                      >
                        {`£${pricePoints[highValue].discount_price}`}
                      </div>
                    </motion.div>
                  ) : null}
                </AnimatePresence>
              </div>
            ))}
          </div>
        </>
      ) : (
        <div className="flex items-center justify-center gap-1">
          <p
            className={`${pricePoints.length > 1 ? `font-medium` : `text-sm`}`}
            style={{
              color:
                'var(--tier' +
                (pricePoints.indexOf(pricePoints[highValue]) + 1).toString() +
                ')',
            }}
          >
            {`£${pricePoints.length > 1 ? pricePoints[lowValue].discount_price : pricePoints[lowValue].discount_price?.toFixed(2)}`}
          </p>
          <span
            className="thumb-value text-white w-max px-2 text-[11px] leading-[17px] font-medium rounded-full"
            style={{
              backgroundColor:
                'var(--tier' +
                (pricePoints.indexOf(pricePoints[highValue]) + 1).toString() +
                ')',
            }}
          >
            {pricePoints[highValue].active_discount * 100}% off
          </span>
        </div>
      )}
    </div>
  )
}

export default PriceSlider
