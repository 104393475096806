import { PlusCircle } from 'lucide-react'
import TabBar from '../general/TabBar'
import { UpSeatLogo } from '@/assets/upseat_logo'
import { pastToday } from '@/utils'
import { useAppStore } from '@/context/useAppStore'
import useCallRouteWithDirection from '@/hooks/useCallRouteWithDirection'
import { useTicketsStore } from '@/context/useTicketsStore'
import { useUserStore } from '@/context/useUserStore'

interface NavigationHeaderProps {
  activeItem: number
  setActiveItem: (item: number) => void
  searchingSeats?: boolean
}

export const NavigationHeader = ({
  activeItem,
  setActiveItem,
  searchingSeats,
}: NavigationHeaderProps) => {
  const { user } = useUserStore()
  const { setSideMenu } = useAppStore()
  const { wallet } = useTicketsStore()
  const callRouteWithDirection = useCallRouteWithDirection()

  return (
    <div className="w-full flex items-center justify-between px-6 pt-3">
      {user && !searchingSeats && (
        <>
          <div
            id="logo-top-nav-btn"
            className="[&>svg]:w-auto [&>svg]:h-[1.75rem] [&>svg]:relative [&>svg]:top-[2px] py-2 cursor-pointer"
            onClick={() => setSideMenu(true)}
          >
            <UpSeatLogo />
          </div>
          {/* {wallet
            ? wallet.find(
                (booking) => !pastToday(new Date(booking.start_time)),
              ) && (
                <div className="relative">
                  <TabBar
                    items={['Tickets', 'Memories']}
                    activeItem={activeItem}
                    setActiveItem={setActiveItem}
                  />
                </div>
              )
            : null} */}
          <PlusCircle
            id="add-top-nav-btn"
            className="py-2 w-10 h-10 cursor-pointer"
            onClick={() => callRouteWithDirection('/sync', false, 0)}
          />
        </>
      )}
    </div>
  )
}
