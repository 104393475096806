import { AnimatePresence, motion } from 'framer-motion'
import { transition, transitionOut } from '@/utils'

import { ArrowRightLong } from '@/assets/ArrowRightLong'
import { Button } from '@/components/ui/button'
import UpAppLogo from '@/assets/upseat_logo.svg'
import useCallRouteWithDirection from '@/hooks/useCallRouteWithDirection'
import useScreenSize from '@/hooks/useScreenSize'

export const Home = () => {
  const callRouteWithDirection = useCallRouteWithDirection()
  const screenSize = useScreenSize()

  return (
    <main className="bg-purple overflow-hidden w-full h-[100svh]">
      <AnimatePresence>
        <motion.div
          transition={transition}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0, transition: transitionOut }}
          className="relative flex flex-col gap-1 w-full h-full justify-center items-center"
        >
          <div className="h-full flex flex-col justify-center">
            <motion.img
              src={UpAppLogo}
              alt="upSeat-logo"
              className={`${screenSize.width > 1024 ? 'w-[5vw]' : 'w-[20vw]'}`}
              initial={{ scale: 0.5 }}
              animate={{ rotate: 0, scale: 1 }}
              transition={{
                type: 'spring',
                stiffness: 260,
                damping: 20,
              }}
            />
          </div>
          <div
            className={`relative bottom-4 w-full flex flex-col gap-4 px-4 flex flex-col ${screenSize.width > 1280 ? 'pl-[33vw] pr-[33vw]' : ''}`}
          >
            <Button
              variant="primaryYellow"
              id="home-signup-btn"
              size={'large'}
              onClick={() => callRouteWithDirection('/sign-up', false, 1)}
              className="w-full"
              data-testid="wallet-login-button"
            >
              {`Sign up`}
              <ArrowRightLong />
            </Button>
            <Button
              variant="secondaryWhite"
              id="home-login-btn"
              className="text-white capitalize w-full"
              onClick={() => callRouteWithDirection('/login', false, 1)}
              data-testid="wallet-login-button"
            >
              {`login`}
            </Button>
          </div>
        </motion.div>
      </AnimatePresence>
    </main>
  )
}
