import { Seat } from '@/types'
import { create } from 'zustand'

// Define the store's state
type CartState = {
  seatsPendingPayment: Seat[]
  setSeatsPendingPayment: (value: Seat[]) => void
  loadingPayment: boolean
  setLoadingPayment: (value: boolean) => void
}

// Create the store
export const useCartStore = create<CartState>((set) => ({
  seatsPendingPayment: [], // initial state
  setSeatsPendingPayment: (value) => set({ seatsPendingPayment: value }),
  loadingPayment: false,
  setLoadingPayment: (value) => set({ loadingPayment: value }),
}))
