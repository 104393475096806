import { Navigate, Route, Routes } from 'react-router-dom'

import { ForgotPassword } from '@/pages/account/ForgotPassword'
import { HelpCentre } from '@/pages/account/HelpCentre'
import { Home } from '@/pages/home'
import { LoginPage } from '@/pages/account/Login'
import { ResetPassword } from '@/pages/account/ResetPassword'
import { SignUpPage } from '@/pages/account/SignUp'

const PublicRoutes = () => {
  return (
    <Routes>
      <Route path="/sign-up" element={<SignUpPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/help-centre" element={<HelpCentre />} />
      <Route path="/reset-my-password/:id" element={<ResetPassword />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/" element={<Home />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  )
}

export default PublicRoutes
