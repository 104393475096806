import { useState } from 'react'

export interface Indexes {
  previousIndex: number
  currentIndex: number
  nextIndex: number
  afternextIndex: number
}

export const useIndexes = (walletLength: number) => {
  const [indexes, setIndexes] = useState<Indexes>({
    previousIndex: walletLength - 1,
    currentIndex: 0,
    nextIndex: 1,
    afternextIndex: 2,
  })

  const handleCardTransition = (backward: boolean) => {
    setIndexes((prevState) => {
      const { currentIndex, nextIndex, afternextIndex, previousIndex } =
        prevState

      if (!backward) {
        // Forward scrolling logic
        return {
          previousIndex:
            walletLength > 2 ? prevState.currentIndex : currentIndex,
          currentIndex: walletLength > 2 ? prevState.nextIndex : nextIndex,
          nextIndex: walletLength > 2 ? prevState.afternextIndex : currentIndex,
          afternextIndex:
            walletLength > 2
              ? (prevState.afternextIndex + 1) % walletLength
              : currentIndex,
        }
      } else {
        // Backward scrolling logic
        return {
          previousIndex: (previousIndex - 1 + walletLength) % walletLength,
          currentIndex:
            walletLength > 2 ? prevState.previousIndex : previousIndex,
          nextIndex: walletLength > 2 ? prevState.currentIndex : currentIndex,
          afternextIndex:
            walletLength > 2
              ? prevState.nextIndex
              : (nextIndex - 1 + walletLength) % walletLength,
        }
      }
    })
  }

  return { indexes, handleCardTransition }
}
