export const LockerIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="18"
    viewBox="0 0 20 16"
    fill="none"
  >
    <path
      d="M15.3333 6V6.5H15.8333H17V14.6667C17 15.0275 16.5863 15.5 15.8334 15.5H4.16668C3.41371 15.5 3 15.0275 3 14.6667V6.5H4.16668H4.66668V6V4.66666C4.66668 2.46793 6.9497 0.5 10 0.5C13.0503 0.5 15.3333 2.46793 15.3333 4.66666V6ZM11.7429 12.8947L11.7428 12.8944L11.522 11.3054C11.9127 10.9816 12.1667 10.5247 12.1667 10C12.1667 8.89013 11.0855 8.16666 10 8.16666C8.91455 8.16666 7.83336 8.89017 7.83336 10C7.83336 10.5247 8.08736 10.9816 8.4781 11.3054L8.25722 12.8944L8.2572 12.8945C8.21833 13.1749 8.33682 13.4286 8.52284 13.595C8.70467 13.7577 8.9412 13.8333 9.16668 13.8333H10.8334C11.0588 13.8333 11.2954 13.7577 11.4772 13.595L11.4773 13.5949C11.6633 13.4284 11.7816 13.1748 11.7429 12.8947ZM13.3333 6.5H13.8333V6V4.66666C13.8333 2.82179 12.0042 1.5 10 1.5C7.99577 1.5 6.16668 2.82179 6.16668 4.66666V6V6.5H6.66668H13.3333Z"
      fill="#F4FF68"
      stroke="#F4FF68"
    />
  </svg>
)
