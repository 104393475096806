import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion'
import { useEffect, useState } from 'react'

import { Button } from '@/components/ui/button'
import DefaultLayout from '@/layouts/DefaultLayout'
import HeadlineBar from '@/components/upseat-ui/general/HeadlineBar'
import NavLayout from '@/layouts/NavLayout'
import RouteTransition from '@/components/routeTransition/RouteTransition'
import { SearchInput } from '@/components/upseat-ui/general/SearchInput'
import useCallRouteWithDirection from '@/hooks/useCallRouteWithDirection'
import { useUserStore } from '@/context/useUserStore'

export const HelpCentre = () => {
  const callRouteWithDirection = useCallRouteWithDirection()
  const [search, setSearch] = useState<string>('')
  const { user } = useUserStore()
  const [searchResults, setSearchResults] = useState<number>(0)
  const content = [
    {
      question: 'Can I upgrade?',
      answer: `Upgrades are limited, your ticket will show if they’re available.`,
    },
    {
      question: 'How do I access my tickets?',
      answer: (
        <div className="flex flex-col gap-2">
          <p>
            {`Barcodes will appear in your UpSeat Wallet whenever you book tickets directly from our partner theatres.`}
          </p>
          <p>{`If you’ve upgraded, your tickets will appear before the show (you'll also receive an email). Remember, your old tickets are no longer valid (but keep hold of them in case the venue requests to see them).`}</p>
        </div>
      ),
    },
    {
      question: 'Can I get a refund?',
      answer: `Tickets or upgrades aren’t refundable.`,
    },
    {
      question: 'What are the terms of use?',
      answer: (
        <p>
          {`Find our Terms of Use, Privacy Policy and Cookie Policy `}
          <a
            className="underline"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.notion.so/upseat/Our-legals-273016cae1d24dd4802f65e788c47631"
          >{`here`}</a>
          {`.`}
        </p>
      ),
    },
  ]

  useEffect(() => {
    setSearchResults(
      content.filter(
        (item) =>
          item.question.toLowerCase().includes(search.toLowerCase()) ||
          (typeof item.answer === 'string'
            ? item.answer.toLowerCase().includes(search.toLowerCase())
            : false),
      ).length,
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  return (
    <DefaultLayout>
      <RouteTransition>
        <div className="flex flex-col justify-start gap-4">
          <HeadlineBar
            title={`help centre`}
            goBack={
              user
                ? () => callRouteWithDirection('/tickets', true, 3)
                : undefined
            }
          />
        </div>

        <main
          className="bg-white relative flex flex-col gap-2 text-purple px-4 pt-6"
          data-testid="help-centre"
        >
          <SearchInput
            search={search}
            setSearch={setSearch}
            data-testid={`search-input`}
          />
          <div
            className="relative border-none bg-purple3 text-white rounded-[20px] p-4 py-2"
            onChange={() => {}}
          >
            <p className="font-body font-normal py-2">
              Need help? Click{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://54dleo9n841.typeform.com/to/zfteuTMG"
                className="underline"
              >
                here
              </a>{' '}
              to get in touch
            </p>
          </div>
          <p className="uppercase text-[12px] text-purple2 pl-2 my-2">
            {search ? `search results` : `frequently asked questions`}
          </p>
          {search && searchResults > 0 && (
            <p className="font-semibold text-purple2 pl-2">{`${searchResults} results for '${search}'`}</p>
          )}
          {search && !searchResults && (
            <div className="flex flex-col gap-1">
              <h4 className="text-h4 text-purple">{'sorry!'}</h4>
              <p className="text-sm font-semibold">{`No results for '${search}'`}</p>
            </div>
          )}
          <div className="relative top-0 h-[100vh] pb-12 overflow-scroll">
            {content
              .filter(
                (item) =>
                  item.question.toLowerCase().includes(search.toLowerCase()) ||
                  (typeof item.answer === 'string'
                    ? item.answer.toLowerCase().includes(search.toLowerCase())
                    : false),
              )
              .map((item, index) => {
                return (
                  <Accordion
                    key={`item-` + index}
                    type="single"
                    collapsible
                    className={`w-full ${index > 0 && 'mt-2'} flex flex-col gap-2`}
                  >
                    <AccordionItem
                      value={`item-${index}`}
                      className="relative border-none bg-grey4 text-purple1 rounded-[20px] p-4 py-2"
                      onChange={() => {}}
                    >
                      <AccordionTrigger className="font-body font-normal">
                        {item.question}
                      </AccordionTrigger>
                      <AccordionContent>
                        <div className="w-full h-full leading-[1.75]">
                          {item.answer}
                        </div>
                      </AccordionContent>
                    </AccordionItem>
                  </Accordion>
                )
              })}
          </div>
          <footer className="bg-purple absolute left-0 bottom-0 text-white w-full h-[10svh] py-4">
            <div className="flex items-center justify-center gap-4">
              <p>{`Found a bug with the app?`}</p>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://54dleo9n841.typeform.com/to/zfteuTMG"
              >
                <Button
                  variant="secondaryDark"
                  // onClick={() => callRouteWithDirection('/contact-us', false, 3)}
                  className="py-2 px-4 text-sm font-normal text-[yellow] border-[yellow]"
                >
                  {`Let us know`}
                </Button>
              </a>
            </div>
          </footer>
        </main>
      </RouteTransition>
    </DefaultLayout>
  )
}
