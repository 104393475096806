import React, { ReactNode } from 'react'

import { UpSeatTiles } from '@/assets/upseat-tiles'
import useScreenSize from '@/hooks/useScreenSize'

type NavLayoutProps = {
  children: ReactNode
  overflowHidden?: boolean
}

const NavLayout: React.FC<NavLayoutProps> = ({ children, overflowHidden }) => {
  const screenSize = useScreenSize()
  return (
    <div
      id="nav-layout"
      className={`app-view bg-purple
      ${screenSize.width > 1024 && 'pl-[33vw] pr-[33vw] overflow-hidden flex flex-col justify-between'}
      ${overflowHidden && 'overflow-hidden'}
      `}
    >
      <main className={`relative`}>{children}</main>
    </div>
  )
}
// h-lvh
export default NavLayout
