import { UpSeatLogo } from '@/assets/upseat_logo'
import { motion } from 'framer-motion'

const SeatsNotFound = () => {
  return (
    <motion.h3
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{
        opacity: 0,
      }}
      className="flex text-h3 px-4 text-center"
    >
      <>
        <figure className="pl-2 [&>svg]:w-auto [&>svg]:h-[2.5rem] [&>svg]:relative [&>svg]:right-[4px] [&>svg]:top-[0px]">
          <UpSeatLogo />
        </figure>
        {`seats not found :(`}
      </>
    </motion.h3>
  )
}

export default SeatsNotFound
