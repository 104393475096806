import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { Eye, EyeOff } from 'lucide-react'
import {
  forgotPasswordFormSchema,
  forgotPasswordFormSchemaType,
} from '../../lib/validation-schemas/forgotPasswordFormSchema'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { AllScreenBlur } from '@/components/upseat-ui/slides/AllScreenBlur'
import { Button } from '@/components/ui/button'
import DefaultLayout from '@/layouts/DefaultLayout'
import HeadlineBar from '@/components/upseat-ui/general/HeadlineBar'
import { Input } from '@/components/ui/input'
import LoadingIndicator from '@/components/upseat-ui/seatMap/LoadingIndicator'
import RouteTransition from '@/components/routeTransition/RouteTransition'
import { changePassword } from '@/service/Login/changePassword'
import { useUserStore } from '@/context/useUserStore'
import { zodResolver } from '@hookform/resolvers/zod'

export const ResetPassword = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { user } = useUserStore()
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [showConfirmationPassword, setShowConfirmationPassword] =
    useState<boolean>(false)
  const [loadingResetPassword, setLoadingResetPassword] =
    useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [passwordSuccessfulChanged, setPasswordSuccessfulChanged] =
    useState<boolean>(false)

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<forgotPasswordFormSchemaType>({
    resolver: zodResolver(forgotPasswordFormSchema),
  })

  useEffect(() => {
    if (passwordSuccessfulChanged) {
      setTimeout(() => {
        navigate(user ? '/tickets' : '/login')
      }, 3000)
    }
  }, [navigate, passwordSuccessfulChanged, user])

  const onSubmit: SubmitHandler<forgotPasswordFormSchemaType> = async (
    data,
  ) => {
    setLoadingResetPassword(true)
    await changePassword(data.newPassword, id as string, data.confirmPassword)
      .then(() => {
        setPasswordSuccessfulChanged(true)
      })
      .catch((err: any) => {
        console.log('Reset password Error:', err.response.data.error)
        setError(true)
      })
      .finally(() => {
        setLoadingResetPassword(false)
      })
  }

  return (
    <DefaultLayout>
      {passwordSuccessfulChanged && (
        <AllScreenBlur dimApp close={() => !passwordSuccessfulChanged}>
          <div className="w-full bg-green1 z-[999] p-4 rounded-[10px]">
            <h1>{`Password successfully changed`}</h1>
          </div>
        </AllScreenBlur>
      )}
      <RouteTransition>
        <div className="flex flex-col justify-start gap-4">
          <HeadlineBar title={`reset your password`} />
        </div>

        <form
          onSubmit={handleSubmit(onSubmit)}
          className="bg-white overflow-scroll flex flex-col justify-between gap-4 text-purple h-[90svh] p-4 py-6"
          autoComplete={'off'}
        >
          <div className="flex flex-col gap-2">
            <p className="relative text-pretty text-purple3 text-sm font-light px-2">{`Password must:`}</p>
            <ul className="list-disc pl-6 mb-2">
              <li className="relative my-2 text-pretty text-purple3 text-sm font-light px-2">{`Be at least 8 characters long`}</li>
              <li className="relative my-2 text-pretty text-purple3 text-sm font-light px-2">{`Have at least one capital letter`}</li>
              <li className="relative my-2 text-pretty text-purple3 text-sm font-light px-2">{`Have at least one symbol`}</li>
            </ul>
            <Controller
              name="newPassword"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <div className="flex flex-col relative">
                  <Input
                    className={`${
                      errors.newPassword?.message && 'border-red'
                    } py-4`}
                    type={showPassword ? 'text' : 'password'}
                    placeholder="New Password"
                    {...field}
                    onChange={(e) => {
                      field.onChange(e)
                      setError(false)
                    }}
                  />
                  <label
                    onClick={() => setShowPassword(!showPassword)}
                    className="absolute top-4 right-4 bg-gray-300 hover:bg-gray-400 rounded text-sm text-gray-600 font-mono cursor-pointer"
                  >
                    {showPassword ? (
                      <Eye color="grey" />
                    ) : (
                      <EyeOff color="grey" />
                    )}
                  </label>

                  {errors.newPassword && (
                    <p className="text-[12px] text-pretty pl-2 text-red">
                      {errors.newPassword.message}
                    </p>
                  )}
                </div>
              )}
            />

            <Controller
              name="confirmPassword"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <div className="flex flex-col relative">
                  <Input
                    className={`${
                      errors.confirmPassword?.message && 'border-red'
                    } py-4`}
                    type={showConfirmationPassword ? 'text' : 'password'}
                    placeholder="Confirm New Password"
                    {...field}
                    onChange={(e) => {
                      field.onChange(e)
                      setError(false)
                    }}
                  />
                  <label
                    onClick={() =>
                      setShowConfirmationPassword(!showConfirmationPassword)
                    }
                    className="absolute top-4 right-4 bg-gray-300 hover:bg-gray-400 rounded text-sm text-gray-600 font-mono cursor-pointer"
                  >
                    {showConfirmationPassword ? (
                      <Eye color="grey" />
                    ) : (
                      <EyeOff color="grey" />
                    )}
                  </label>

                  {errors.confirmPassword && (
                    <p className="text-[12px] text-pretty pl-2 text-red">
                      {errors.confirmPassword.message}
                    </p>
                  )}
                </div>
              )}
            />
            {error && (
              <p className="text-red text-center text-[12px] text-balance relative top-[-5px]">
                {`Error resetting password. Please try again.`}
              </p>
            )}
          </div>

          <div className="py-4">
            {!loadingResetPassword ? (
              <Button
                variant="primaryDark"
                className="w-full font-normal py-4"
                type="submit"
              >{`Confirm`}</Button>
            ) : (
              <div className="py-4">
                <LoadingIndicator dark />
              </div>
            )}
            <div className="w-full flex justify-center py-4">
              <p
                className="relative my-2 text-center text-purple3 cursor-pointer w-fit"
                onClick={() => navigate(user ? '/tickets' : '/login')}
              >{`Cancel`}</p>
            </div>
          </div>
        </form>
      </RouteTransition>
    </DefaultLayout>
  )
}
