import { Dispatch, SetStateAction } from 'react'
import { cleanTokenFromLocalStorage, deleteDatabase } from '@/utils'

import { CogIcon } from '@/assets/CogIcon'
import { ProfileIcon } from '@/assets/ProfileIcon'
import { motion } from 'framer-motion'
import { useAppStore } from '@/context/useAppStore'
import useCallRouteWithDirection from '@/hooks/useCallRouteWithDirection'
import useExistingBookingStore from '@/context/useExistingBookingStore'
import useScreenSize from '@/hooks/useScreenSize'
import { useTicketsStore } from '@/context/useTicketsStore'
import { useUserStore } from '@/context/useUserStore'

export const SideMenu = () => {
  const screenSize = useScreenSize()
  const callRouteWithDirection = useCallRouteWithDirection()
  const { user, unsetUser } = useUserStore()
  const { setSideMenu, sideMenu } = useAppStore()
  const { setBooking } = useExistingBookingStore()
  const { setWallet } = useTicketsStore()

  return (
    <div className="z-100">
      {sideMenu && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.5 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          className="fixed inset-0 bg-black z-[999]"
          onClick={() => setSideMenu(false)}
          style={{ pointerEvents: sideMenu ? 'auto' : 'none' }}
        />
      )}

      {/* Side Menu */}
      <motion.div
        initial={{ x: '-100%' }}
        animate={{ x: sideMenu ? 0 : '-100%' }}
        transition={{ type: 'spring', stiffness: 120, damping: 20, mass: 0.2 }}
        className={`fixed top-0 left-0 h-full bg-purple1 text-white shadow-lg z-[999] ${screenSize.width > 1280 ? 'w-[25%]' : 'w-[75%]'}`}
      >
        <ul className="[&>li]:cursor-pointer p-6 pt-10 h-full flex flex-col justify-between">
          <div>
            <li
              onClick={() =>
                callRouteWithDirection('/personal-details', false, 2)
              }
            >
              <div className="py-6 border-b border-purple2 flex justify-between items-center">
                <p className="text-purple7 flex gap-4 items-center cursor-pointer">
                  <span>
                    <ProfileIcon />
                  </span>
                  {`Personal Details`}
                </p>
              </div>
            </li>
            {/* <li
                      onClick={() =>
                        callRouteWithDirection('/payment-details', false, 2)
                      }
                    >
                      <div className="py-6 border-b border-purple2 flex justify-between items-center">
                        <p className="text-purple7">{`Payment Details`}</p>
                      </div>
                    </li> */}
            <li onClick={() => callRouteWithDirection('/account', false, 2)}>
              <div className="py-6 border-b border-purple2 flex justify-between items-center">
                <p className="text-purple7 flex gap-4 items-center cursor-pointer">
                  <span>
                    <CogIcon />
                  </span>
                  {`Account`}
                </p>
              </div>
            </li>
          </div>
          <div>
            <li
              onClick={() => callRouteWithDirection('/help-centre', false, 2)}
            >
              <div className="py-6 border-b border-purple2 flex justify-between items-center">
                <p className="text-purple7 cursor-pointer">{`Help Centre`}</p>
              </div>
            </li>
            <li onClick={() => callRouteWithDirection('/partners', false, 2)}>
              <div
                className={`flex py-6 border-b border-purple2 justify-between items-center`}
              >
                <p className="text-purple7 cursor-pointer">{`Partners`}</p>
              </div>
            </li>
            <li
              onClick={async () => {
                await unsetUser()
                await cleanTokenFromLocalStorage()
                await setWallet(undefined)
                await setBooking(undefined)

                await deleteDatabase('keyval-store').then((res: any) =>
                  window.location.reload(),
                )
              }}
            >
              <div className={`flex py-6 justify-between items-center`}>
                <p className="text-purple7 cursor-pointer">{`Logout`}</p>
              </div>
            </li>
          </div>
        </ul>
      </motion.div>
    </div>
  )
}
